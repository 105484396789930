import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { IndustryComponent } from './industry/industry.component';
import { ProductsComponent } from './products/products.component';
import { StandardsComponent } from './standards/standards.component';
import { OemComponent } from './oem/oem.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { AutomationComponent } from './automation/automation.component';
import { HeaderComponent } from './header/header.component';
import { ContactUsComponent } from './contact-us/contact-us.component';

const routes: Routes = [

  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'industry', component: IndustryComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'standards', component: StandardsComponent },
  { path: 'oem', component: OemComponent },
  { path: 'equipment', component: EquipmentComponent },
  { path: 'automation', component: AutomationComponent },
  { path: 'industry', component: IndustryComponent },
  { path: 'automation', component: AutomationComponent },
  { path: 'header', component: HeaderComponent },
  { path: 'contact-us', component: ContactUsComponent }





];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
