import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oem',
  templateUrl: './oem.component.html',
  styleUrls: ['./oem.component.scss']
})
export class OemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
